<template>
  <div class="home" id="hero-bg">
    <div class="display-3" id="light-text-shadow">Tag Line</div>
    <div class="display-5 container" id="light-text-shadow">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Necessitatibus nam doloremque eligendi?
    </div>
  </div>
  <div class="container-md p-4">
    <div>
      <h2 class="display-5" id="light-text">Services</h2>
    </div>
    <div class="container-sm">
      <div class="row row-cols-1 row-cols-md-3 g-4">
        <div class="col">
          <div class="card h-100">
            <img src="../assets/image_1.webp" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.</p>
            </div>
            <div class="card-footer">
              <small class="text-body-secondary">Last updated 3 mins ago</small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img src="../assets/image_2.webp" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">This card has supporting text below as a natural lead-in to additional content.</p>
            </div>
            <div class="card-footer">
              <small class="text-body-secondary">Last updated 3 mins ago</small>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card h-100">
            <img src="../assets/image_3.webp" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
            </div>
            <div class="card-footer">
              <small class="text-body-secondary">Last updated 3 mins ago</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-md">
    <div class="display-5" id="light-text">
      Why Us
    </div>
    <div class="card text-center m-3">
      <div class="card-header">
        Featured
      </div>
      <div class="card-body">
        <h5 class="card-title">Special title treatment</h5>
        <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
        <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
      <div class="card-footer text-body-secondary">
        2 days ago
      </div>
    </div>
    <div class="row p-3">
      <div class="col-sm-6 mb-3 mb-sm-0">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Special title treatment</h5>
            <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
            <a href="#" class="btn btn-primary">Go somewhere</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LandingCarousel from '@/components/LandingCarousel.vue'
import JobCard from '@/components/JobCard.vue'

export default {
  name: 'HomeView',
  components: {
    LandingCarousel,
    JobCard
  }
}
</script>

<style>
#hero-bg {
  background-image: url("../assets/image_0.webp");
  height: 96vh;
  color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#services-block {
  display: flex;
  //flex-direction: column;
  justify-content: space-evenly;
  //align-items: center;
}

#choose-block {
  display: flex;
  //flex-direction: column;
  justify-content: space-evenly;
  //align-items: center;
}

#light-text {
  color: rgb(225, 225, 225);
}

#light-text-shadow {
  filter: drop-shadow(0px 0px 6px rgb(32,32,32,128));
}
</style>