<template>
  <div id="body-content">
    <!-- Header -->
    <nav  class="navbar navbar-expand-lg" id="header-block">
      <div class="container-fluid">

        <!-- Branding -->
        <router-link class="navbar-brand" to="/">
            <img src="./assets/logo.svg" alt="Logo" width="32" height="32">
        </router-link>

        <!-- Collapsed navigation button -->
        <button class="navbar-toggler bg-body-tertiary" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-main" aria-controls="navbar-main" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Navbar menu -->
        <div class="collapse navbar-collapse" id="navbar-main">
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item" id="header-button">
              <router-link class="nav-link active" aria-current="page" to="/">Home</router-link>
            </li>
            <li class="nav-item" id="header-button">
              <router-link class="nav-link" aria-current="page" to="/portfolio">Portfolio</router-link>
            </li>
            <li class="nav-item" id="header-button">
              <router-link class="nav-link" aria-current="page" to="/investors">Investors</router-link>
            </li>
            <li class="nav-item" id="header-button">
              <router-link class="nav-link" aria-current="page" to="/jobs">Jobs</router-link>
            </li>
            <li class="nav-item" id="header-button">
              <router-link class="nav-link" aria-current="page" to="/contact">Contact</router-link>
            </li>
            <li class="nav-item dropdown" id="header-button">
              <router-link class="nav-link dropdown-toggle" to="/contact" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                About Us
              </router-link>
              <ul class="dropdown-menu dropdown-menu-end">
                <li class="nav-item">
                  <router-link class="dropdown-item" to="/">Home</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="dropdown-item" to="/portfolio">Portfolio</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="dropdown-item" to="/investors">Investors</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Routed content -->
    <div id="view-content">
      <router-view/>
    </div>

    <!-- Footer -->
    <div class="container-flex" id="footer-block">
      <footer class="container d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <div class="col-md-4 d-flex align-items-center">
          <span class="mb-3 mb-md-0" id="footer-text">
            <img src="./assets/logo.svg" alt="Logo" width="32" height="32">
            © 2024 Asphalt Botswana Pty Ltd
          </span>
        </div>

        <ul class="nav col-md-4 justify-content-end list-unstyled d-flex" >
          <li class="ms-3">
            <a class="" href="https://web.facebook.com/ASPHALTBOTS/?_rdc=1&_rdr">
              <Icon icon="mdi:facebook" color="rgb(225,225,225)" :ssr="true" />
            </a>
          </li>
          <li class="ms-3">
            <a class="" href="https://youtube.com/">
              <Icon icon="mdi:youtube" color="rgb(225,225,225)" :ssr="true" />
            </a>
          </li>
        </ul>
      </footer>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'App',
  components: {
    Icon
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

#body-content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(48,48,48);
}

#view-content {
  flex-grow: 1;
}

#header-block {
  background-color: rgb(32, 32, 32);
}

#header-button {
  :not(:hover) {
    color: rgb(225,225,225);
  }
  :hover {
    color: crimson;
  }
  :focus {
    color: brown;
  }
  :active {
    color: white;
  }
}

#footer-block {
  background-color: rgb(32, 32, 32);
}

#footer-text {
  color: rgb(225, 225, 225);
}
</style>
